:root {
    --primary-color: #2c3e50;
    --secondary-color: #34495e;
    --accent-color: #3498db;
    --background-color: #f8f9fa;
    --text-color: #333;
    --code-background: #f1f3f5; /* Slightly off-white for code background */
    --border-color: #e0e0e0;
}

.dark-theme {
    --primary-color: #1a2634;
    --secondary-color: #2c3e50;
    --accent-color: #3498db;
    --background-color: #121212;
    --text-color: #e0e0e0;
    --code-background: #1e1e1e;
    --border-color: #444;
}

body {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: var(--background-color);
    color: var(--text-color);
    padding-top: 70px;
    transition: background-color 0.3s, color 0.3s;
}

.navbar {
    background-color: var(--primary-color);
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.navbar-brand {
    color: #fff !important;
    font-weight: bold;
    font-size: 1.25rem;
}

.code-section {
    height: calc(50vh - 45px);
    margin-bottom: 15px;
    position: relative;
    background-color: var(--background-color);
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
    padding: 15px;
    display: flex;
    flex-direction: column;
}

.code-container {
    background-color: var(--prism-background);
    border: 1px solid var(--border-color);
    border-radius: 4px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 1em;
    margin-bottom: 15px; /* Add bottom margin */
}

pre[class*="language-"] {
    margin: 0;
    padding: 0;
    flex-grow: 1; /* Allow pre to grow and fill the container */
    min-height: 100%; /* Ensure pre takes up full height of container */
}

.line-numbers .line-numbers-rows {
    border-right: 1px solid var(--border-color);
}

pre {
    background-color: var(--code-background);
    padding: 15px;
    font-size: 14px;
    font-family: 'Courier New', Courier, monospace;
    height: 100%;
    overflow-x: auto;
    margin-bottom: 0;
    color: var(--text-color);
}

#generateBtn {
    background-color: var(--accent-color);
    border-color: var(--accent-color);
}

#generateBtn:hover {
    background-color: #2980b9;
    border-color: #2980b9;
}

.loading {
    display: none;
}

.expand-btn {
    position: absolute;
    top: 15px;
    right: 15px;
    background: none;
    border: none;
    color: var(--accent-color);
    z-index: 10;
}

.fullscreen {
    position: fixed;
    top: 70px;
    left: 0;
    width: 100%;
    height: calc(100% - 70px);
    z-index: 9999;
    margin: 0;
    padding: 20px;
    background-color: var(--background-color);
}

#differences-container {
    position: relative;
    background-color: var(--background-color);
    border-radius: 8px;
    padding: 15px;
    margin-top: 10px;
    height: calc(50vh - 45px);
    overflow-y: auto;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

#differences-label {
    position: absolute;
    top: 15px;
    right: 15px;
    background-color: var(--accent-color);
    color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 0.9em;
}

.lang-select {
    width: auto;
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
}

.form-select {
    padding-right: 30px;
    background-color: var(--background-color);
    color: var(--text-color);
    border-color: var(--border-color);
}

.select-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

#differences {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

#differences h1, #differences h2, #differences h3 {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    color: var(--text-color);
}

#differences ul, #differences ol {
    padding-left: 20px;
}

#differences code {
    background-color: var(--code-background);
    padding: 2px 4px;
    border-radius: 4px;
    font-family: 'Courier New', Courier, monospace;
    color: var(--text-color);
}

#differences pre {
    background-color: var(--code-background);
    padding: 10px;
    border-radius: 4px;
    overflow-x: auto;
    color: var(--text-color);
}

#differences pre code {
    background-color: transparent;
    padding: 0;
}

#differences table {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 1em;
}

#differences th, #differences td {
    border: 1px solid var(--border-color);
    padding: 8px;
    text-align: left;
    background-color: var(--background-color);
    color: var(--text-color);
}

#differences th {
    background-color: var(--secondary-color);
    color: #fff;
}

.default-prompt {
    background-color: #e6f7ff;
}

.selected-prompt {
    background-color: #e9ecef;
    font-weight: bold;
}

.default-prompt.selected-prompt {
    background-color: #bae7ff;
    font-weight: bold;
}

.prompt-item:hover {
    background-color: #f8f9fa;
    cursor: pointer;
}

.default-prompt:hover {
    background-color: #91d5ff;
}

.selected-prompt:hover,
.default-prompt.selected-prompt:hover {
    background-color: #d4e5f7;
}

.prompt-modal {
    max-width: 90vw;
    width: 90vw;
    margin: 2rem auto;
}

.prompt-modal .modal-content {
    height: 90vh;
    display: flex;
    flex-direction: column;
    background-color: var(--background-color);
    color: var(--text-color);
}

.prompt-modal .modal-body {
    flex: 1;
    overflow: hidden;
    padding: 0;
}

.prompt-modal-content {
    display: flex;
    height: 100%;
}

.prompt-list-container {
    width: 30%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    border-right: 1px solid var(--border-color);
}

.prompt-list {
    flex: 1;
    overflow-y: auto;
}

.prompt-edit-container {
    width: 70%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
}

.prompt-edit-form {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.prompt-name-group {
    margin-bottom: 1rem;
}

.prompt-content-group {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.prompt-content-group textarea {
    flex: 1;
    resize: none;
}

.prompt-modal .modal-footer {
    border-top: 1px solid var(--border-color);
}

/* Disable all transitions and animations */
.prompt-modal,
.prompt-modal *,
.modal.fade,
.modal.fade .modal-dialog,
.prompt-list-item,
.modal-backdrop {
    transition: none !important;
    animation: none !important;
}

/* Ensure no flicker or repaint */
.prompt-modal {
    backface-visibility: hidden;
    transform: translateZ(0);
    -webkit-font-smoothing: subpixel-antialiased;
}

/* Immediately set backdrop opacity */
.modal-backdrop {
    opacity: 0.5 !important;
}

.default-prompt {
    background-color: #e6f7ff;
  }
  
  .selected-prompt {
    background-color: #e9ecef;
    font-weight: bold;
  }
  
  .default-prompt.selected-prompt {
    background-color: #bae7ff;
    font-weight: bold;
  }
  
  .prompt-item:hover {
    background-color: #f8f9fa;
    cursor: pointer;
  }
  
  .default-prompt:hover, .default-selected-prompt:hover {
    background-color: #91d5ff;
  }
  

/* For default text in source and target code sections   */

.default-text pre {
    color: #888;
    text-shadow: 1px 1px 1px rgba(255,255,255,0.5);
    font-style: italic;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .default-text code {
    background: none !important;
    white-space: normal !important;
    word-break: break-word;
  }

  /* Key Differences */

  .key-differences h6 {
    color: var(--text-color);
    background-color: var(--background-color);
    padding: 5px 10px;
    margin-bottom: 15px;
    text-align: right;
    border-radius: 5px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow: hidden;
    float: right;
    clear: both;
  }

  .dark-theme .key-differences h6 {
    background-color: var(--primary-color);
    color: #ffffff;
  }

  .differences-content {
    color: var(--text-color);
    padding-left: 1em; /* Add left padding to match code-container */
  }

  .dark-theme .differences-content {
    color: var(--text-color);
  }

  /* Overlay */ 
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .overlay::after {
    content: "Generating...";
    color: white;
    font-size: 24px;
    font-weight: bold;
  }

  /* Manage Prompts Button */
  .manage-prompts-btn {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--accent-color);
    color: #fff;
    border: none;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    z-index: 1000;
  }
  
  .manage-prompts-btn:hover {
    background-color: #2980b9;
  }

.theme-toggle-btn,
.manage-prompts-btn {
  position: fixed;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  padding: 0.5rem;
  transition: color 0.3s ease;
  z-index: 1000;
  right: 20px;
}

.theme-toggle-btn {
  bottom: 70px;
}

.manage-prompts-btn {
  bottom: 20px;
}

/* Light theme styles */
.theme-toggle-btn,
.manage-prompts-btn {
  color: var(--text-color);
}

.theme-toggle-btn:hover,
.manage-prompts-btn:hover {
  color: var(--accent-color);
}

/* Dark theme styles */
.dark-theme .theme-toggle-btn,
.dark-theme .manage-prompts-btn {
  color: #ffffff;
}

.dark-theme .theme-toggle-btn:hover,
.dark-theme .manage-prompts-btn:hover {
  color: var(--accent-color);
}

/* Light theme (default) Prism.js styles */
:root {
  --prism-background: #f5f2f0;
  --prism-color: #000;
  --prism-shadow: 0 1px white;
  --prism-selection-background: #b3d4fc;
}

/* Dark theme styles (unchanged) */
.dark-theme {
  --prism-background: #1e1e1e;
  --prism-color: #f8f8f2;
  --prism-shadow: none;
  --prism-selection-background: #444;
}

code[class*="language-"],
pre[class*="language-"] {
  color: var(--prism-color);
  background: none;
  text-shadow: var(--prism-shadow);
  font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
  font-size: 1em;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;
  tab-size: 4;
  hyphens: none;
}

pre[class*="language-"]::-moz-selection, pre[class*="language-"] ::-moz-selection,
code[class*="language-"]::-moz-selection, code[class*="language-"] ::-moz-selection {
  text-shadow: none;
  background: var(--prism-selection-background);
}

pre[class*="language-"]::selection, pre[class*="language-"] ::selection,
code[class*="language-"]::selection, code[class*="language-"] ::selection {
  text-shadow: none;
  background: var(--prism-selection-background);
}

@media print {
  code[class*="language-"],
  pre[class*="language-"] {
    text-shadow: none;
  }
}

/* Code blocks */
pre[class*="language-"] {
  padding: 1em;
  margin: .5em 0;
  overflow: auto;
}

:not(pre) > code[class*="language-"],
pre[class*="language-"] {
  background: var(--prism-background);
}

/* Inline code */
:not(pre) > code[class*="language-"] {
  padding: .1em;
  border-radius: .3em;
  white-space: normal;
}

/* Light theme token styles */
:root {
  --comment-color: slategray;
  --punctuation-color: #999;
  --property-color: #905;
  --string-color: #690;
  --operator-color: #9a6e3a;
  --keyword-color: #07a;
  --function-color: #DD4A68;
  --variable-color: #e90;
}

/* Dark theme token styles (unchanged) */
.dark-theme {
  --comment-color: #6a9955;
  --punctuation-color: #d4d4d4;
  --property-color: #f92672;
  --string-color: #a6e22e;
  --operator-color: #f8f8f2;
  --keyword-color: #66d9ef;
  --function-color: #e6db74;
  --variable-color: #fd971f;
}

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: var(--comment-color);
}

.token.punctuation {
  color: var(--punctuation-color);
}

.token.namespace {
  opacity: .7;
}

.token.property,
.token.tag,
.token.boolean,
.token.number,
.token.constant,
.token.symbol,
.token.deleted {
  color: var(--property-color);
}

.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
  color: var(--string-color);
}

.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string {
  color: var(--operator-color);
  background: none; /* Remove the background */
}

.token.atrule,
.token.attr-value,
.token.keyword {
  color: var(--keyword-color);
}

.token.function,
.token.class-name {
  color: var(--function-color);
}

.token.regex,
.token.important,
.token.variable {
  color: var(--variable-color);
}

.token.important,
.token.bold {
  font-weight: bold;
}

.token.italic {
  font-style: italic;
}

.token.entity {
  cursor: help;
}

/* Update code container styles */
.code-container {
  background-color: var(--prism-background);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  flex-grow: 1; /* Allow the container to grow and fill available space */
  overflow-y: auto; /* Keep this to allow scrolling within the code container */
  padding: 1em; /* Add padding inside the container */
}

/* Update the parent container of the code block */
.source-pane {
  height: 100%;
  overflow: hidden; /* Change this from 'auto' to 'hidden' */
  display: flex;
  flex-direction: column;
}

/* Update styles for the pre element inside the code container */
.code-container pre {
  margin: 0; /* Remove default margins */
  padding: 0; /* Remove default padding */
}

/* Ensure consistent background in dark theme */
.dark-theme .code-container,
.dark-theme pre[class*="language-"] {
  background-color: var(--prism-background);
}

/* Current Prompt Styling */
.current-prompt {
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.7) !important;
  font-weight: normal;
  padding: 0.25rem 0.5rem;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  margin-left: 1rem;
}
